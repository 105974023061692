import React from 'react'
import Breadcrumb from './Breadcrumb';

import { ContactNav } from '../Data/BreadcrumbData'



const ContactPage = () => {
	return (
		<>
			<div>
				<Breadcrumb  {...ContactNav} />
				<div class="contact-us pt-90 pb-90">
					<div class="container">
						<div class="row">
							<div class="col-sm-12 col-md-6 col-lg-6 pl-0 pr-0">
								<div class="contact_from_box">
									<div class="contact_title pb-4">
										<h3>Get In Touch</h3>
									</div>
									<form id="contact_form" action="mail.php" method="POST">
										<div class="row">
											<div class="col-lg-6">
												<label>Full Name</label>
												<div class="form_box mb-30">
													<input type="text" name="name" placeholder="Name" required />
												</div>
											</div>
											<div class="col-lg-6">
												<label>Email Address</label>
												<div class="form_box mb-30">
													<input type="email" name="email" placeholder="Email" required />
												</div>
											</div>
											<div class="col-lg-6">
												<label>Phone Number</label>
												<div class="form_box mb-30">
													<input type="text" name="phone" placeholder="Phone" required />
												</div>
											</div>
											<div class="col-lg-6">
												<label>Website</label>
												<div class="form_box mb-30">
													<input type="text" name="web" placeholder="Website" required />
												</div>
											</div>
											<div class="col-lg-12">
												<label>Your Message</label>
												<div class="form_box mb-30">
													<textarea name="message" id="message" cols="30" rows="10" placeholder="Your Message" required></textarea>
												</div>
												<div class="quote_btn">
													<button class="btn" type="submit">Send Message</button>
												</div>
											</div>
										</div>
									</form>
									<p class="form-message"></p>
								</div>
							</div>

							{/*<div class="col-sm-12 col-md-6 col-lg-6 pl-0 pr-0">
								<div class="cda-content-area">
									<div class="cda-single-content d-flex">
										<div class="cda-icon">
											<i class="bi bi-globe"></i>
										</div>
										<div class="cda-content-inner">
											<h4>Company Location</h4>
											<p>Novel Tech Park, Hosur Rd, Kudlu Gate, Krishna Reddy Industrial Area, Hosapalaya, Garvebhavi Palya, Bengaluru, Karnataka -560068.</p>
										</div>
									</div>
									<div class="cda-single-content hr d-flex">
										<div class="cda-icon">
											<i class="bi bi-telephone"></i>
										</div>
										<div class="cda-content-inner">
											<h4>Telephone Number</h4>
											 <p>080-43729615  <br /> </p>
										</div>
									</div>
									<div class="cda-single-content hr d-flex">
										<div class="cda-icon">
											<i class="bi bi-envelope"></i>
										</div>

										<div class="cda-content-inner">
											<h4>Our Email Address</h4>
											<p> <h4><a href="mailto:info@valuesoftinfo.com"><span className="primary-white"> info@valuesoftinfo.com </span> </a></h4> </p>
										</div>
									</div>
									
									<div class="cda-content text-left">
                    			<ul class="social">
                        			<li class="active" ><a href="https://www.facebook.com/valuesoftinfo/"  target="_blank"><i class="bi bi-facebook"></i></a></li>
                        			<li><a href="https://twitter.com/ValueSoft_info"  target="_blank"><i class="bi bi-twitter"></i></a></li>
                       			
                        			<li><a href="https://www.instagram.com/valuesoftinfo"  target="_blank"><i class="bi bi-instagram"></i></a></li>
									<li><a href="https://www.linkedin.com/company/value-soft-info-services-pvt-ltd-your-global-it-partner/" target="_blank"><i class="bi bi-linkedin"></i></a></li>
                    			</ul>
                				</div>
								</div>
							</div>*/}
							<div class="col-sm-12 col-md-6 col-lg-6 pl-0 pr-0">
								<div class="contact-card">
									<h5>USA Region Office</h5>
									<ul>
										<li>
											<div class="icon"><i class="bi bi-map"></i></div>
											<div class="text">
												Address: 10513 N MacArthur blvd, Suit 2025,<br />
												Irving,TX, 75063.</div>
										</li>
										<li>
											<div class="icon"><i class="bi bi-telephone"></i></div>
											<div class="text">
												+1(732)-497-2606</div>
										</li>
										<li>
											<div class="icon"><i class="bi bi-envelope"></i></div>
											<div class="text"><a href="mailto:Info@valuesoftinfo.com">info@valuesoftinfo.com</a></div>
										</li>
									</ul>


								</div>
								<div class="contact-card">


									<h5>Malaysia Office</h5>
									<ul>
										<li>
											<div class="icon"><i class="bi bi-map"></i></div>
											<div class="text">Level 16, 1 Sentral, Jalan Stesen Sentral 5
												KL Sentral, 50470 Kuala Lumpur, Malaysia.</div>
										</li>
										<li>
											<div class="icon"><i class="bi bi-telephone"></i></div>
											<div class="text">
												+60 (3)2092 9277</div>
										</li>
										<li>
											<div class="icon"><i class="bi bi-envelope"></i></div>
											<div class="text"><a href="mailto:Info@valuesoftinfo.com">info@valuesoftinfo.com</a></div>
										</li>
									</ul>



								</div>

								<div class="contact-card">


									<h5>Bangalore Office </h5>
									<ul>
										<li>
											<div class="icon"><i class="bi bi-map"></i></div>
											<div class="text">Novel Tech Park, Hosur Rd, Kudlu Gate, Garvebhavi Palya, Bengaluru, Karnataka - 560068</div>
										</li>
										<li>
											<div class="icon"><i class="bi bi-telephone"></i></div>
											<div class="text">
												080-43729615</div>
										</li>
										<li>
											<div class="icon"><i class="bi bi-envelope"></i></div>
											<div class="text"><a href="mailto:Info@valuesoftinfo.com">info@valuesoftinfo.com</a></div>
										</li>
									</ul>


								</div>
								<div class="contact-card">


									<h5>UAE Office</h5>
									<ul>
										<li>
											<div class="icon"><i class="bi bi-map"></i></div>
											<div class="text"> Dubai Office Address - Unit 401-404, DhanGuard center LLC, Bank street road, Dubai UAE</div>
										</li>
										<li>
											<div class="icon"><i class="bi bi-telephone"></i></div>
											<div class="text">
												+971-54-3201101 / +971-50-6974148 </div>
										</li>
										<li>
											<div class="icon"><i class="bi bi-envelope"></i></div>
											<div class="text"><a href="mailto:Info@valuesoftinfo.com">info@valuesoftinfo.com</a></div>
										</li>
									</ul>

								</div>

							</div>
						</div>
					</div>
				</div>


			</div>

			{/*<section class="contact-info">
				<div class="container">
					<div class="row mx-auto">
						<div class="col-md-6 offset-2">
							<div class="contact-card">


								<h5>USA Region Office</h5>
								<ul>
									<li>
										<div class="icon"><i class="bi bi-map"></i></div>
										<div class="text">
											Address: 10513 N MacArthur blvd, Suit 2025,<br />
											Irving,TX, 75063.</div>
									</li>
									<li>
										<div class="icon"><i class="bi bi-telephone"></i></div>
										<div class="text">
											+1(732)-497-2606</div>
									</li>
									<li>
										<div class="icon"><i class="bi bi-envelope"></i></div>
										<div class="text"><a href="mailto:Info@valuesoftinfo.com">info@valuesoftinfo.com</a></div>
									</li>
								</ul>


							</div>
						</div>


						<div class="col-md-4">
							<div class="contact-card">


								<h5>Malaysia Office</h5>
								<ul>
									<li>
										<div class="icon"><i class="bi bi-map"></i></div>
										<div class="text">Level 16, 1 Sentral, Jalan Stesen Sentral 5
											KL Sentral, 50470 Kuala Lumpur, Malaysia.</div>
									</li>
									<li>
										<div class="icon"><i class="bi bi-telephone"></i></div>
										<div class="text">
											+60 (3)2092 9277</div>
									</li>
									<li>
										<div class="icon"><i class="bi bi-envelope"></i></div>
										<div class="text"><a href="mailto:Info@valuesoftinfo.com">info@valuesoftinfo.com</a></div>
									</li>
								</ul>



							</div>
						</div>

					</div>
					<div class="row mx-auto mt-2 mb-5">
						<div class="col-md-4 offset-2">
							<div class="contact-card">


								<h5>Bangalore Office </h5>
								<ul>
									<li>
										<div class="icon"><i class="bi bi-map"></i></div>
										<div class="text">Novel Tech Park, Hosur Rd, Kudlu Gate, Garvebhavi Palya, Bengaluru, Karnataka - 560068</div>
									</li>
									<li>
										<div class="icon"><i class="bi bi-telephone"></i></div>
										<div class="text">
											080-43729615</div>
									</li>
									<li>
										<div class="icon"><i class="bi bi-envelope"></i></div>
										<div class="text"><a href="mailto:Info@valuesoftinfo.com">info@valuesoftinfo.com</a></div>
									</li>
								</ul>


							</div>


						</div>
						<div class="col-md-4 ">
							<div class="contact-card">


								<h5>UAE Office</h5>
								<ul>
									<li>
										<div class="icon"><i class="bi bi-map"></i></div>
										<div class="text"> Dubai Office Address - Unit 401-404, DhanGuard centre LLC, Bank street road, Dubai UAE</div>
									</li>
									<li>
										<div class="icon"><i class="bi bi-telephone"></i></div>
										<div class="text">
											+971-54-3201101 / +971-50-6974148 </div>
									</li>
									<li>
										<div class="icon"><i class="bi bi-envelope"></i></div>
										<div class="text"><a href="mailto:Info@valuesoftinfo.com">info@valuesoftinfo.com</a></div>
									</li>
								</ul>

							</div>
						</div>
					</div>

				</div>
			</section>*/}

		</>
	)
}
export default ContactPage;