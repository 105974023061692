import React from "react";
import '../scss/ContactForm.scss';




const ContactForm = () => {

    return (
        <section id="contact">
            <section className="val-contact ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <h5  data-aos="flip-up">CONTACT US</h5>
                        </div>
                        <div className="col-md-9">

                            <h3 className="mb-5 primary-white" data-aos="fade-up"> <span className="primary-black">We support you,  </span> in  the best possbile way. <span className="primary-black"></span> </h3>
                            <p > Our approach ensures true measurable business value by proactively bringing continuous improvements, cost savings, innovation and a “best-in-class” transformational blueprint to clients.</p>

                            <form>
                                <div className="row mb-3">
                                    <div className="col-6">
                                        <input type="text" class="form-control" placeholder="First Name *" />
                                    </div>
                                    <div className="col-6">
                                        <input type="text" class="form-control" placeholder="Last Name *" />
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-6">
                                        <input type="email" class="form-control" placeholder="Email *" />
                                    </div>
                                    <div className="col-6">
                                        <input type="text" class="form-control" placeholder="Subject Title *" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="5" placeholder="Your Message Here*"></textarea>
                                    </div>

                                </div>
                    
                                <input type="submit" className="secondary_cta" value="Submit"></input>
                            </form>
                        </div>
                    </div>
                </div>

            </section>
            <section className="val-clients">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <h5 className="primary-black" data-aos="flip-up">OUR CLIENTS</h5>
                        </div>
                        <div className="col-md-9">

                            <h3 className="mb-5 primary-black" data-aos="fade-up"> <span className="primary-orange">We design </span>INSPIRING BRANDS & ONLINE EXPERIENCES FOR FORWARD-THINKING CLIENTS<span className="primary-orange">.</span> </h3>
                        </div>

                    </div>
                    <div className="row mt-5">
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/yash-technologies-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/aq4-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/bandgi-technologies-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/byteridge-logo-white.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/cigniti-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>

                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/covalensedigital-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/cybertech-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/deloitte-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>

                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/MASQ.AE.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/HCL-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/hitachi-Vantara.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/flender-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/grocery-factory-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/siemens-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/wipro-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/tcs-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>                        
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/tech-M-logo.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        <div className="col">
                        <img src={process.env.PUBLIC_URL + '/images/clients/GIB.png'} className="mb-4" alt="Valuesoft-Clinets" />
                        </div>
                        
                    </div>
                </div>
            </section>
        </section>


    );


}

export default ContactForm;