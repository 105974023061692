import React from 'react'
import Breadcrumb from './Breadcrumb';
import Article from './Article';
import ArticleFeature from './ArticleFeature';
import LeftColumn from './Left-common';
import { StaffingAugmentationNav } from '../Data/BreadcrumbData'
import { StaffingAugmentationAD } from '../Data/ArticleData'
import { StaffingAugmentationArticle } from '../Data/ArticleFeatureData';
import { SATitle } from '../Data/LeftColmnsData'

const StaffingAugmentationPage = () => {
    return (
        <>
            <div>
                <Breadcrumb  {...StaffingAugmentationNav} />
                <div class="single-service pt-75 pb-75">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4">
                                <LeftColumn {...SATitle} />

                            </div>
                            <div class="col-lg-8">
                                <Article {...StaffingAugmentationAD} />


                                <div class="ap-section-content">
                                    <div class="overview-content">
                                        <h2 class="overview-title"> Our Staff Augmentation Services Include:</h2>
                                        <div class="row">
                                        <div class="col-md-12"> 
                                        <ul class="list-style-bullet">
                                            <li>Skill-Based Augmentation: Leverage top-tier professionals with specific expertise to fill immediate gaps in your team, ensuring your projects never lose momentum.</li> <li>Time-Based Augmentation: Access talented professionals on a flexible, time-bound basis, allowing your business to scale workforce capacity during peak demand periods.</li> <li>Hybrid Model: Benefit from a combination of onshore and offshore resources, blending cost-effectiveness with local oversight to deliver the perfect balance for your projects.</li><li>Onshore Augmentation: Deploy skilled professionals locally for seamless integration into your existing teams, ensuring alignment with your organization’s culture and processes.</li>
                                            <li>
                                                Offshore Augmentation: Reduce costs without compromising quality by tapping into our offshore talent pool, providing round-the-clock productivity for your global business needs.
                                            </li>

                                            <li>
                                                Dedicated Team: Build a dedicated team of experts focused solely on your project or business, ensuring consistent delivery, control, and quality over the long term.
                                            </li>

                                            <li>
                                                Project-Based Augmentation: Quickly scale up or down with specialists who focus on completing specific projects, providing the flexibility to adapt to dynamic business needs.
                                            </li>
                                        </ul>
                                        </div>
                                        </div>
                                          <div class="row">
                                            <div class="col-md-6 col-lg-6">
                                                <div class="overview-thumb mb-30">
                                                    <img src="/images/innerpage/Staff_Aug_2.jpg"></img>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6">
                                                <div class="ap-content-inner d-flex">
                                                    <div class="ap-content-icon">
                                                        <i class="bi bi-check-circle"></i>
                                                    </div>
                                                <div class="ap-content-text ml-20"> Why Choose Valuesoft Info?</div>
                                            </div>
                                            <div class="ap-content-inner d-flex mt-10">{/*
                                                <div class="ap-content-icon">
                                                    <i class="bi bi-check-circle"></i>
                                                </div>*/}
                                                <div class="row">
                                        <div class="col-md-12"> 
                                        <ul class="list-style-bullet">
                                            <li>	Flexible Engagement Models: Tailor solutions to fit your unique business needs, whether short-term, long-term, or project specific.</li>
                                            <li>
                                             Global Talent Pool: Access a broad range of highly skilled professionals from various locations.
                                            </li>

                                            <li>
                                             Cost-Effective Solutions: Balance quality with competitive pricing, allowing you to maximize efficiency without compromising on talent.
                                            </li>

                                            <li>
                                            	Seamless Integration: Our professionals integrate effortlessly into your teams, ensuring smooth collaboration and swift project progress.
                                            </li>

                                            
                                        </ul>
                                        </div>
                                        </div>
                                                </div></div></div><p class="pt-15 pb-15">Partner with Valuesoft Info to enhance your workforce, scale with confidence, and accelerate your business success.</p></div></div>


                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </>
    )
}
export default StaffingAugmentationPage;